@import '~@/assets/scss/main.scss';

.btn-circle {
    $button-size: 8rem;
    width: $button-size;
    height: $button-size;
    border-radius: 100%;
    padding-top: 1rem;
    opacity: 0.7;
    transition: opacity $transition-duration;

    /**
        Hide elite validation label for custom radio buttons
        A properly structured label wraps the component
     */
    label {
        display: none !important;
    }

    .img-button {
        height: 6rem;
    }

    &.reason-active {
        opacity: 1;
        max-width: 8rem;
    }

    .reason-label {
        transition: all $transition-duration;
    }
}
.carousel-size {
    width: 20rem;
    height: 100%;
}
